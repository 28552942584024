import axios from 'axios';
import https from 'https';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { useAdminConfigStore, usePrismicStore, useProfessionalsStore } from '@/zustandStore';

export const FeatureKey = {
    CONSENT_FORMS: 'feature-consent-forms',
    ANAMNESIS_BUILDER: 'feature-anamnesis-builder',
    CUSTOM_ANAMNESIS: 'feature-custom-anamnesis',
    USER_MANAGEMENT: 'feature-user-management',
    ACCESS_REQUESTS: 'feature-access-requests',
};

export const InsuranceType = {
    PUBLIC: 'PUBLIC',
    PRIVATE: 'PRIVATE',
    SELF_PAYER: 'SELF_PAYER',
};

export const getCustomerId = () => {
    const customer = usePrismicStore.getState().currentCustomer;
    return customer?.customer_id?.[0]?.text;
};

export function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

export const swrFetcher = ([url, config]) => axios.get(url, config).then((res) => res.data);

export const getHealthProfileData = async (userToken, id) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: userToken,
            customer_id: getCustomerId(),
        },
    };
    try {
        const res = await axios.get(
            `${import.meta.env.VITE_PROTOCOL}://${import.meta.env.VITE_PATIENT_API_DOMAIN}/${
                import.meta.env.VITE_SECURE_ADMIN_API_PATH
            }/user/health-profiles/${id}`,
            config
        );
        console.debug(res);
        return res;
    } catch (error) {
        console.debug(error);
        return error;
    }
};

export const getExternalPatients = async (userToken) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: userToken,
            customer_id: getCustomerId(),
        },
    };

    try {
        const response = await axios.get(
            `${import.meta.env.VITE_PROTOCOL}://${import.meta.env.VITE_PATIENT_API_DOMAIN}/${
                import.meta.env.VITE_SECURE_ADMIN_API_PATH
            }/user/form-data/external-form-data`,
            config
        );
        console.debug(response);
        return [response?.data?.data, 200];
    } catch (error) {
        console.debug(error);
        return [null, error.response.status];
    }
};

export const getExternalPatientDetails = async (userToken, userId) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: userToken,
            customer_id: getCustomerId(),
        },
    };

    try {
        const response = await axios.get(
            `${import.meta.env.VITE_PROTOCOL}://${import.meta.env.VITE_PATIENT_API_DOMAIN}/${
                import.meta.env.VITE_SECURE_ADMIN_API_PATH
            }/user/form-data/${userId}`,
            config
        );
        console.debug(response);
        return response?.data;
    } catch (error) {
        console.debug(error);
        return null;
    }
};

export const getAdminUsers = async (userToken) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: userToken,
            customer_id: getCustomerId(),
        },
    };

    try {
        const response = await axios.get(
            `${import.meta.env.VITE_PROTOCOL}://${import.meta.env.VITE_ADMIN_API_DOMAIN}/${
                import.meta.env.VITE_SECURE_ADMIN_API_PATH
            }/users`,
            config
        );
        console.debug(response);
        return response.data.data;
    } catch (error) {
        console.debug(error);
        return null;
    }
};

export const syncPatientWithId = async (userToken, instanceId, dcPatientId) => {
    const config = {
        headers: {
            Authorization: userToken,
            customer_id: getCustomerId(),
        },
        params: {
            instance_id: instanceId,
            dc_user_id: dcPatientId,
        },
    };

    try {
        const response = await axios.post(
            `${import.meta.env.VITE_PROTOCOL}://${import.meta.env.VITE_PATIENT_API_DOMAIN}/${
                import.meta.env.VITE_SECURE_ADMIN_API_PATH
            }/doc-cirrus/user/sync`,
            {},
            config
        );
        console.debug(response);
        return response.status === 200;
    } catch (error) {
        console.debug(error);
        return error.status;
    }
};

export const getSharedDocumentsByUserId = async (userToken, userId) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: userToken,
            customer_id: getCustomerId(),
        },
    };

    try {
        const response = await axios.get(
            `${import.meta.env.VITE_PROTOCOL}://${import.meta.env.VITE_PATIENT_API_DOMAIN}/${
                import.meta.env.VITE_SECURE_ADMIN_API_PATH
            }/user/documents/shared?user_id=${userId}`,
            config
        );
        console.debug(response);
        return response.data?.data;
    } catch (error) {
        console.debug(error);
        return null;
    }
};

export const getDocumentsByUserAndAppointment = async (userToken, userId, appointmentId) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: userToken,
            customer_id: getCustomerId(),
        },
        params: { user_id: userId, appointment_id: appointmentId },
    };

    try {
        const response = await axios.get(
            `${import.meta.env.VITE_PROTOCOL}://${import.meta.env.VITE_PATIENT_API_DOMAIN}/${import.meta.env.VITE_SECURE_ADMIN_API_PATH}/user/documents/uploaded`,
            config
        );
        return response.data?.data;
    } catch (error) {
        console.debug(error);
        return null;
    }
};

export const getDocumentDownloadUrl = async (userToken, userId, documentId) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: userToken,
            customer_id: getCustomerId(),
        },
        params: { user_id: userId },
    };

    try {
        const response = await axios.get(
            `${import.meta.env.VITE_PROTOCOL}://${import.meta.env.VITE_PATIENT_API_DOMAIN}/${import.meta.env.VITE_SECURE_ADMIN_API_PATH}/user/documents/${documentId}`,
            config
        );
        return [true, response.data, undefined];
    } catch (error) {
        console.warn(error);

        return [false, undefined, error];
    }
};

export const generateDocumentDownloadCode = async (userToken, userId, email) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: userToken,
            customer_id: getCustomerId(),
        },
    };

    try {
        await axios.post(
            `${import.meta.env.VITE_PROTOCOL}://${import.meta.env.VITE_PATIENT_API_DOMAIN}/${import.meta.env.VITE_SECURE_ADMIN_API_PATH}/user/documents/generate-download-code`,
            { email: email },
            config
        );
        return [true, undefined];
    } catch (error) {
        console.warn(error);

        return [false, error];
    }
};

export const validateDocumentDownloadCode = async (userToken, userId, code) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: userToken,
            customer_id: getCustomerId(),
        },
    };

    try {
        await axios.post(
            `${import.meta.env.VITE_PROTOCOL}://${import.meta.env.VITE_PATIENT_API_DOMAIN}/${import.meta.env.VITE_SECURE_ADMIN_API_PATH}/user/documents/validate-download-code`,
            { code: code },
            config
        );
        return [true, undefined];
    } catch (error) {
        console.warn(error);
        return [false, error?.response];
    }
};

export const getExtAuthToken = async (userToken, instanceId) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: userToken,
            customer_id: getCustomerId(),
        },
    };

    try {
        const response = await axios.get(
            `${import.meta.env.VITE_PROTOCOL}://${import.meta.env.VITE_PATIENT_API_DOMAIN}/${
                import.meta.env.VITE_SECURE_ADMIN_API_PATH
            }/call-system/ext/auth?instance_id=${instanceId}`,
            config
        );
        return response.data?.token;
    } catch (error) {
        console.debug(error);
        return null;
    }
};

export const getUnknownAppointments = async (userToken, locationKey) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: userToken,
            customer_id: getCustomerId(),
        },
    };

    try {
        const response = await axios.get(
            `${import.meta.env.VITE_PROTOCOL}://${import.meta.env.VITE_PATIENT_API_DOMAIN}/${
                import.meta.env.VITE_SECURE_ADMIN_API_PATH
            }/call-system/users/unknown?location_key=${locationKey}`,
            config
        );
        console.debug(response);
        return response.data;
    } catch (error) {
        console.debug(error);
        return null;
    }
};

export const printPcsTicket = async (authToken, endpoint, certificateText) => {
    try {
        const axiosInstance = axios.create({
            httpsAgent: new https.Agent({
                rejectUnauthorized: false,
                cert: certificateText,
            }),
            headers: {
                token: authToken,
            },
        });
        const response = await axiosInstance.get(`${import.meta.env.VITE_PROTOCOL}://${endpoint}/`);
        console.debug(response);
        return response.data?.id;
    } catch (error) {
        console.debug(error);
        return null;
    }
};

export const updatePcs = async (authToken, endpoint, certificateText, stack, ticket = null) => {
    try {
        const axiosInstance = axios.create({
            httpsAgent: new https.Agent({
                rejectUnauthorized: false,
                cert: certificateText,
            }),
            headers: {
                token: authToken,
            },
        });
        const response = await axiosInstance.get(
            `${import.meta.env.VITE_PROTOCOL}://${endpoint}/?stack=${stack}${ticket ? `&ticket=${ticket}` : ''}`
        );
        console.debug(response);
        if (ticket) return response.status;
        return response.data?.id;
    } catch (error) {
        console.debug(error);
        return null;
    }
};

export const saveScopes = async (token, scopes, user) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
            customer_id: getCustomerId(),
        },
    };

    const data = {
        id: user.id,
        username: user.username,
        email: user.email,
        scopes: scopes,
    };

    try {
        const response = await axios.post(
            `${import.meta.env.VITE_PROTOCOL}://${import.meta.env.VITE_ADMIN_API_DOMAIN}/${
                import.meta.env.VITE_SECURE_ADMIN_API_PATH
            }/users/scopes`,
            data,
            config
        );

        console.debug(response);
        return true;
    } catch (error) {
        console.debug(error);
        return false;
    }
};

export const createUser = async (token, user_email_list) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
            customer_id: getCustomerId(),
        },
    };

    const promises = user_email_list.map((user_email) => {
        const data = {
            email: user_email,
        };

        return axios
            .post(
                `${import.meta.env.VITE_PROTOCOL}://${import.meta.env.VITE_ADMIN_API_DOMAIN}/${
                    import.meta.env.VITE_SECURE_ADMIN_API_PATH
                }/users/create-user`,
                data,
                config
            )
            .then((res) => {
                console.debug(res);
                return {
                    user_email: user_email,
                    success: true,
                };
            })
            .catch((e) => {
                console.debug(e);
                return {
                    user_email: user_email,
                    success: false,
                    message: e.response?.data?.error ?? e.message,
                };
            });
    });

    const resolvedPromises = await Promise.all(promises);
    return resolvedPromises;
};

export const saveCustomers = async (token, customer_ids, instance_ids, user) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
            customer_id: getCustomerId(),
        },
    };

    const data = {
        id: user.id,
        username: user.username,
        email: user.email,
        customer_ids: customer_ids,
        instance_ids: instance_ids,
    };

    try {
        const response = await axios.post(
            `${import.meta.env.VITE_PROTOCOL}://${import.meta.env.VITE_ADMIN_API_DOMAIN}/${
                import.meta.env.VITE_SECURE_ADMIN_API_PATH
            }/users/customers`,
            data,
            config
        );

        console.debug(response);
        return true;
    } catch (error) {
        console.debug(error);
        return false;
    }
};

export const addGroup = async (token, group, user) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
            customer_id: getCustomerId(),
        },
    };

    const data = {
        id: user.id,
        username: user.username,
        email: user.email,
        group: group,
    };

    try {
        const response = await axios.post(
            `${import.meta.env.VITE_PROTOCOL}://${import.meta.env.VITE_ADMIN_API_DOMAIN}/${
                import.meta.env.VITE_SECURE_ADMIN_API_PATH
            }/users/add-group`,
            data,
            config
        );

        console.debug(response);
        return true;
    } catch (error) {
        console.debug(error);
        return false;
    }
};

export const removeGroup = async (token, group, user) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
            customer_id: getCustomerId(),
        },
    };

    const data = {
        id: user.id,
        username: user.username,
        email: user.email,
        group: group,
    };

    try {
        const response = await axios.post(
            `${import.meta.env.VITE_PROTOCOL}://${import.meta.env.VITE_ADMIN_API_DOMAIN}/${
                import.meta.env.VITE_SECURE_ADMIN_API_PATH
            }/users/remove-group`,
            data,
            config
        );

        console.debug(response);
        return true;
    } catch (error) {
        console.debug(error);
        return false;
    }
};

export const getAllGroups = async (token) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
            customer_id: getCustomerId(),
        },
    };

    try {
        const response = await axios.get(
            `${import.meta.env.VITE_PROTOCOL}://${import.meta.env.VITE_ADMIN_API_DOMAIN}/${
                import.meta.env.VITE_SECURE_ADMIN_API_PATH
            }/users/groups`,
            config
        );

        console.debug(response);
        return response.data;
    } catch (error) {
        console.debug(error);
        return false;
    }
};

export const isLocalEnvironment = () => {
    return window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
};

export const isEternoDomain = () => window.location.hostname.includes(import.meta.env.VITE_ETERNO_DOMAIN);

export const isCorrectWhiteLabelDomain = (domain) => window.location.hostname.includes(domain);

export const getDomain = (customer) => {
    if (isEternoDomain()) return 'eterno-health.io';

    if (isCorrectWhiteLabelDomain(customer.domain)) return `${customer.domain}/customer/${customer.key[0].text}`;

    return `gesund.cloud/customer/${customer.key[0].text}`;
};

export const hasUndefinedProperty = (arr) => {
    for (const obj of arr) {
        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key] === undefined) {
                return true;
            }
        }
    }
    return false;
};

export const getAccessRequest = async (userToken) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: userToken,
            customer_id: import.meta.env.VITE_ETERNO_CUSTOMER_ID,
        },
    };

    try {
        const response = await axios.get(
            `${import.meta.env.VITE_PROTOCOL}://${import.meta.env.VITE_ADMIN_API_DOMAIN}/${
                import.meta.env.VITE_SECURE_ADMIN_API_PATH
            }/users/access-request`,
            config
        );
        console.debug(response);

        useAdminConfigStore.setState({
            accessRequest: response.data?.data,
        });

        return true;
    } catch (error) {
        console.debug(error);
        return false;
    }
};

export const getAccessRequests = async (userToken) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: userToken,
            customer_id: import.meta.env.VITE_ETERNO_CUSTOMER_ID,
        },
    };

    try {
        const response = await axios.get(
            `${import.meta.env.VITE_PROTOCOL}://${import.meta.env.VITE_ADMIN_API_DOMAIN}/${
                import.meta.env.VITE_SECURE_ADMIN_API_PATH
            }/users/access-requests`,
            config
        );
        console.debug(response);

        return response.data?.data;
    } catch (error) {
        if (error.status === 404) return [];
        console.debug(error);
        return null;
    }
};

export const sendAccessRequest = async (userToken, body) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: userToken,
            customer_id: import.meta.env.VITE_ETERNO_CUSTOMER_ID,
        },
    };

    try {
        const response = await axios.post(
            `${import.meta.env.VITE_PROTOCOL}://${import.meta.env.VITE_ADMIN_API_DOMAIN}/${
                import.meta.env.VITE_SECURE_ADMIN_API_PATH
            }/users/access-requests`,
            body,
            config
        );
        console.debug(response);
        return response.status === 201;
    } catch (error) {
        console.debug(error);
        return false;
    }
};

export const sendDecision = async (userToken, body) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: userToken,
            customer_id: import.meta.env.VITE_ETERNO_CUSTOMER_ID,
        },
    };

    try {
        const response = await axios.post(
            `${import.meta.env.VITE_PROTOCOL}://${import.meta.env.VITE_ADMIN_API_DOMAIN}/${
                import.meta.env.VITE_SECURE_ADMIN_API_PATH
            }/users/access-requests/decisions`,
            body,
            config
        );
        console.debug(response);
        return [response.status === 200, response.data?.data];
    } catch (error) {
        console.debug(error);
        return [false, null];
    }
};

export const getPrismicText = (prismicField) => {
    return prismicField?.[0]?.text ?? '';
};

const BASE_URL = `${import.meta.env.VITE_PROTOCOL}://${import.meta.env.VITE_PRACTITIONER_API_DOMAIN}/${
    import.meta.env.VITE_SECURE_ADMIN_API_PATH
}/`;
const BASE_URL_PATIENT = `${import.meta.env.VITE_PROTOCOL}://${import.meta.env.VITE_PATIENT_API_DOMAIN}/${
    import.meta.env.VITE_SECURE_ADMIN_API_PATH
}/`;

export const getCachedUserData = async (userToken) => {
    const config = {
        headers: {
            Authorization: userToken,
            customer_id: getCustomerId(),
        },
        params: {
            data_type: 'linked_users',
        },
    };
    try {
        const res = await axios.get(`${BASE_URL}users/availability/cached-data`, config);
        return res;
    } catch (error) {
        return error;
    }
};

export const loadCachedUserData = async (userToken) => {
    try {
        useProfessionalsStore.setState({ finalRecords: [] });
        const response = await getCachedUserData(userToken);

        if (!response?.data?.data?.linked_users) return false;

        useProfessionalsStore.setState({ cachedUserData: response?.data?.data?.linked_users });
        return true;
    } catch (e) {
        console.debug(e);
        return false;
    }
};

export const updateForm = async (userToken, formData) => {
    const config = {
        headers: {
            Authorization: userToken,
            customer_id: getCustomerId(),
        },
    };
    try {
        const res = await axios.post(`${BASE_URL_PATIENT}user/form-data/templates`, formData, config);
        return res;
    } catch (error) {
        return error;
    }
};

export const getErrorMessageFromPrismicContent = (code, content) =>
    content[code][0]?.text ?? 'Es ist ein Fehler aufgetreten, bitte versuchen Sie es erneut';

export const getStringAfterLastDot = (str) => {
    return str.substring(str.lastIndexOf('.') + 1);
};
