import { CButton, CFormSelect, CModal, CModalBody, CModalHeader } from '@coreui/react';
import cloneDeep from 'lodash.clonedeep';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AWSContext from '@/context/AWSContext';
import ProfessionalContext from '@/context/professional/ProfessionalContext';

import { updateForm } from '@/utils/helpers';

import { useProfessionalsStore } from '@/zustandStore';

const WizardPopup = ({
    showAnamnesisBuilderWizard,
    setShowAnamnesisBuilderWizard,
    professionals,
    selectedProfessional,
    setWizardLoading,
}) => {
    const { userToken } = useContext(AWSContext);
    const history = useHistory();
    const { formsTemplates } = useContext(ProfessionalContext);
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [selectedProfessionalTarget, setSelectedProfessionalTarget] = useState(null);

    useEffect(() => {
        setSelectedTemplate('');
    }, [selectedProfessional]);

    const professionalOptions = useMemo(
        () =>
            professionals.map((professional) => ({
                label: professional.data.display_name[0].text,
                value: professional.dc_user_mapping.user_id,
            })),
        [professionals]
    );

    const createDuplicate = async () => {
        setWizardLoading(true);
        setShowAnamnesisBuilderWizard(false);

        const duplicateForm = cloneDeep(formsTemplates.find((template) => selectedTemplate === template.id));

        if (!duplicateForm) {
            setWizardLoading(false);
            setShowAnamnesisBuilderWizard(true);
            return;
        }

        delete duplicateForm.id;
        duplicateForm.form_name_de = `${duplicateForm.form_name_de} - Duplicate`;
        duplicateForm.form_name_en = `${duplicateForm.form_name_en} - Duplicate`;

        duplicateForm.professional_id = selectedProfessionalTarget.dc_user_mapping.user_id;
        duplicateForm.professional = {
            key: selectedProfessionalTarget.dc_user_mapping.prismic_key,
            id: selectedProfessionalTarget.dc_user_mapping.user_id,
        };

        const response = await updateForm(userToken, duplicateForm);

        if (!response.data) {
            setWizardLoading(false);
            setShowAnamnesisBuilderWizard(true);
            return;
        }

        history.push(
            `/anamnesis-builder?template_id=${response.data.id}&professional_id=${response.data.professional.id}`
        );
    };

    return (
        <CModal scrollable visible={showAnamnesisBuilderWizard} onClose={() => setShowAnamnesisBuilderWizard(false)}>
            <CModalHeader>Anamnesis Builder Wizard</CModalHeader>
            <CModalBody className="d-flex flex-column align-items-center gap-4 p-4 text-center">
                <h5>Starte mit einem neuen Template oder dupliziere ein schon existierendes</h5>
                <CButton color="secondary" onClick={() => history.push('/anamnesis-builder')}>
                    Neues Template
                </CButton>
                <h5>oder</h5>
                <div style={{ width: '80%' }} className="d-flex flex-column gap-3">
                    <h6>Wähle ein Template</h6>
                    <CFormSelect
                        options={professionalOptions}
                        value={selectedProfessional?.dc_user_mapping?.user_id}
                        onChange={(e) =>
                            useProfessionalsStore.setState({
                                childData: professionals.find(
                                    (professional) => professional.dc_user_mapping.user_id === e.target.value
                                ),
                            })
                        }
                        name="professionalSelect"
                    />
                    <CFormSelect
                        options={[
                            {
                                label: 'Template auswählen',
                                value: '-',
                            },
                            ...formsTemplates
                                .filter((template) => template.is_custom)
                                .map((template) => ({
                                    label: template.form_name_de,
                                    value: template.id,
                                })),
                        ]}
                        value={selectedTemplate}
                        onChange={(e) => setSelectedTemplate(e.target.value)}
                        name="professionalSelect"
                    />
                    <h6>kopiere zu</h6>
                    <CFormSelect
                        options={[{ label: 'Professional auswählen', value: '-' }, ...professionalOptions]}
                        value={selectedProfessionalTarget?.dc_user_mapping?.user_id}
                        onChange={(e) =>
                            setSelectedProfessionalTarget(
                                professionals.find(
                                    (professional) => professional.dc_user_mapping.user_id === e.target.value
                                )
                            )
                        }
                        name="professionalSelect"
                    />
                    <div>
                        <CButton
                            onClick={createDuplicate}
                            disabled={
                                !selectedTemplate || !selectedProfessionalTarget || selectedProfessionalTarget === '-'
                            }
                        >
                            Starten
                        </CButton>
                    </div>
                </div>
            </CModalBody>
        </CModal>
    );
};

export default WizardPopup;
